<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  폴더 변경(이름을 ID로 변경)
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn color="light-green" tile dark @click="search"><v-icon>mdi-magnify</v-icon> 조회</v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <v-row>
            <v-col cols="4">
              <v-card outlined tile>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>사용자({{ usersCnt > 0 ? usersCnt : 0 }})</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
                <v-list>
                  <v-list-item-group v-model="itemIndex" color="primary">
                    <v-list-item
                      v-for="(item, i) in users"
                      :key="i"
                      @click="selectAcademy(item, i)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.email }} ({{ item.displayName }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="8">
              <v-card outlined tile>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        :items="folders"
                        v-model="folder"
                        label="폴더를 선택하세요"
                        item-text="name"
                        item-value="id"
                        outlined
                        single-line
                        class="pt-1"
                        @change="changeFolder"
                      >
                        <template slot="no-data">
                          <v-alert>폴더가 없습니다</v-alert>
                        </template>
                      </v-select>
                      <v-text-field
                        :value="folder"
                        label="Folder ID"
                        outlined
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn color="light-green" tile dark @click="editFolderName"><v-icon>mdi-pencil</v-icon> 수정</v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-list>
                <v-list-item
                  v-for="(item, index) in mediaItems"
                  :key="index"
                  style="border-bottom: 1px solid #d3d3d3"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ index + 1 }}. {{ item.id }}</v-list-item-title>
                    <v-list-item-subtitle>title : {{ item.title }}</v-list-item-subtitle>
                    <v-list-item-subtitle>folder : {{ item.folder }}</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import LibraryCard from './components/LibraryCard'
// import LibraryItemAddCard from './components/LibraryItemAddCard'

export default {
  // components: { LibraryCard, LibraryItemAddCard },
  data () {
    return {
      users: [],
      usersCnt: 0,
      itemIndex: 0,
      selectedUser: {},
      activeTab: 0,
      folders: [],
      folder: '',
      clearItems: '',
      mediaItems: []
    }
  },
  computed: {
    //
  },
  methods: {
    async search () {
      this.$firebase.firestore().collection('users')
        .orderBy('email')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.users = []
          }
          this.usersCnt = sn.size

          this.users = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              email: item.email,
              emailVerified: item.emailVerified,
              displayName: item.displayName,
              acceptTerms: item.acceptTerms,
              disabled: item.disabled,
              level: item.level,
              createdAt: item.createdAt.toDate(),
              lastVisitAt: item.visitedAt.toDate()
            }
          })
          // console.log(JSON.stringify(this.users))
        })
    },
    selectAcademy (item, index) {
      this.selectedUser = item

      this.getFolders()
      this.clearItems = new Date().getTime()
    },
    getFolders () {
      this.$firebase.firestore().collection('academies').doc(this.selectedUser.id).collection('folders')
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.folders = []
            return
          }

          this.folders = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name
            }
          })
          // console.log('###', JSON.stringify(this.folders))
        })
    },
    getFolderName (id) {
      const fn = this.folders.find(f => f.id === id)
      return fn.name
    },
    async editFolderName () {
      for (const item of this.mediaItems) {
        console.log(item.id, item.folder, this.folder)
        try {
          await this.$firebase.firestore().collection('libraries').doc(item.id)
            .update({
              folder: this.folder
            })
        } catch (e) {
          console.log(e.message)
        }
      }
    },
    changeFolder () {
      // folder 조회
      const ref = this.$firebase.firestore().collection('libraries')
        .where('academy', '==', this.selectedUser.id)
        .where('folder', '==', this.getFolderName(this.folder))
        // .where('folder', '==', this.folder)
        .orderBy('title')

      ref.onSnapshot((sn) => {
        if (sn.empty) {
          this.mediaItems = []
          return
        }

        this.mediaItems = sn.docs.map(v => {
          const item = v.data()
          return {
            id: v.id,
            title: item.title,
            type: item.type,
            folder: item.folder,
            // folder: this.getFolderName(item.folder),
            imgName: item.imageName,
            imgUrl: item.imageUrl,
            // aFilename: item.audioFilename,
            // aFileUrl: item.audioUrl,
            audioFiles: item.audioFiles,
            pdfFilename: item.pdfFilename,
            pdfFileUrl: item.pdfUrl,
            yUrl: item.youtubeUrl,
            dur: item.duration
          }
        })
        // console.log('###', JSON.stringify(this.mediaItems))
      })
    }
  }
}
</script>
